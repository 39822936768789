body {
  font-family: "Open Sans", sans-serif;
  color: #333333;
  font-size: 18px;
}

#content {
  margin: 0 16px;
  max-width: 480px;
}

#name {
  font-weight: 700;
  color: #ff851b;
}

#tagline {
  font-size: 1.1em;
  line-height: 1.7em;
}

#maya {
  text-decoration: wavy underline #ff851b;
  cursor: pointer;
}

#maya:hover:after {
  content: url(maya.jpeg);
  margin-top: 20px;
  display: block;
}

a,
a:visited {
  color: #0074d9;
  text-decoration: underline;
}

a:hover {
  color: #001f3f;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  margin-bottom: 8px;
}

li::before {
  content: "👉";
  margin-right: 12px;
}
